// Core
import React, { FC } from 'react';
// DX
import DateBox from "devextreme-react/date-box";
// Mui
import EventIcon from '@mui/icons-material/Event';
import { styled } from '@mui/material/styles';
import ButtonMui from '@mui/material/Button';
// Engine
import i18n from "../../../../../../engine/config/i18n";
// Helpers and hooks
import { formatDate } from "../../../../../../_helpers/formatDate";
import { useMediaDownMd } from "../../../../../hooks/useDesktopView";
// Parts
import { ToolbarItemType } from "../../..";
import Dialog from "../../../../Dialog";

const DefaultButtonMui = styled(ButtonMui)(() => ({
  textTransform: 'capitalize',
}));

const Component:FC<{ item: ToolbarItemType, name: string }> = React.memo((props) => {
  const { item, name } = props;
  const isMobile = useMediaDownMd();
  const currentValue = window.location.search;
  const defaultValue = currentValue.includes(name) ? new Date(currentValue.substring(currentValue.indexOf('=') + 1)) : new Date();
  const onChange = (event: any) => window.location.replace(`${window.location.pathname}/?${item.name}=${formatDate(event.value, 'YYYY-MM-DD')}`);
  const onClickToday = () => window.location.replace(`${window.location.pathname}/?${item.name}=${formatDate(new Date(), 'YYYY-MM-DD')}`);
  const onClickYesterday = () => window.location.replace(`${window.location.pathname}/?${item.name}=${formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'YYYY-MM-DD')}`);

  return (
    <label className="dx-toolbar-item-content__wrapper" style={{ maxWidth: '100%' }}>
      {!isMobile && <span className="dx-field-toolbar-item-label">{item.placeholder}</span>}
      <span className="dx-field-toolbar-item-date"><DateBox
        onValueChanged={onChange}
        defaultValue={defaultValue}
        type="date"
        displayFormat="dd.MM.yyyy"
      /></span>
      {isMobile && <br />}
      &nbsp;<DefaultButtonMui variant="contained" size="small" onClick={onClickToday}>{i18n.t('today')}</DefaultButtonMui>
      &nbsp;<DefaultButtonMui variant="contained" size="small" onClick={onClickYesterday}>{i18n.t('yesterday')}</DefaultButtonMui>
    </label>
  )
});

const DataPicker:FC<{ item: ToolbarItemType, name: string }> = (props) => {
  const isMobile = useMediaDownMd();
  const { item, name } = props;
  return isMobile ? <Dialog iconButton={<EventIcon fontSize="inherit" />} item={item}><Component item={item} name={name} /></Dialog> : <Component item={item} name={name} />
};

export default DataPicker;
