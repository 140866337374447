// Core
import React, { FC, useState } from 'react';
// DX
import DateBox from "devextreme-react/date-box";
// Mui
import EventIcon from '@mui/icons-material/Event';
import { styled } from '@mui/material/styles';
import ButtonMui from '@mui/material/Button';
// Engine
import i18n from "../../../../../../engine/config/i18n";
// Helpers and hooks
import { formatDate } from "../../../../../../_helpers/formatDate";
import { useMediaDownMd } from "../../../../../hooks/useDesktopView";
// Parts
import { ToolbarItemType } from "../../..";
import Dialog from "../../../../Dialog";

const DefaultButtonMui = styled(ButtonMui)(() => ({
  textTransform: 'capitalize',
}));

const params = new URLSearchParams(window.location.search);

const Component:FC<{ item: ToolbarItemType, name: string }> = React.memo((props) => {
  const { item } = props;
  const isMobile = useMediaDownMd();
  // @ts-ignore
  const defaultValueDateFrom = params.get('dateFrom') ? new Date(params.get('dateFrom')) : new Date();
  // @ts-ignore
  const defaultValueDateTo = params.get('dateTo') ? new Date(params.get('dateTo')) : new Date();
  const [dateSelected, setDateSelected] = useState({
    dateFrom: formatDate(defaultValueDateFrom, 'YYYY-MM-DD'),
    dateTo: formatDate(defaultValueDateTo, 'YYYY-MM-DD')
  });
  const [isErrorSelectedDates, setIsErrorSelectedDates] = useState(false);
  const onFilter = () => window.location.replace(`${window.location.pathname}/?dateFrom=${dateSelected.dateFrom}&dateTo=${dateSelected.dateTo}`);
  const onClickToday = () => window.location.replace(`${window.location.pathname}/?dateFrom=${formatDate(new Date(), 'YYYY-MM-DD')}&dateTo=${formatDate(new Date(), 'YYYY-MM-DD')}`);
  const onClickYesterday = () => window.location.replace(`${window.location.pathname}/?dateFrom=${formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'YYYY-MM-DD')}&dateTo=${formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'YYYY-MM-DD')}`);

  const handleDateSelected = (date: Date, type: string) => {
    setDateSelected((prevDateSelected) => {
      type === 'dateTo' && formatDate(date, 'YYYY-MM-DD') < prevDateSelected.dateFrom ? setIsErrorSelectedDates(true) : setIsErrorSelectedDates(false);

      return {
        ...prevDateSelected,
        [type]: formatDate(date, 'YYYY-MM-DD')
      }
    })
  }

  return (
    <label className="dx-toolbar-item-content__wrapper" style={{ maxWidth: '100%' }}>
      {!isMobile && <span className="dx-field-toolbar-item-label">{item.placeholder}</span>}
      <span className="dx-field-toolbar-item-date dateFrom"><DateBox
        onValueChanged={(event) => handleDateSelected(event.value, 'dateFrom')}
        defaultValue={defaultValueDateFrom}
        type="date"
        displayFormat="dd.MM.yyyy"
      /></span>
      {!isMobile && <span>-</span>}
      <span className="dx-field-toolbar-item-date dateTo"><DateBox
        onValueChanged={(event) => handleDateSelected(event.value, 'dateTo')}
        defaultValue={defaultValueDateTo}
        type="date"
        displayFormat="dd.MM.yyyy"
        style={{ backgroundColor: isErrorSelectedDates ? '#f32e381c' : '' }}
      /></span>

      {isMobile && <br/>}
      &nbsp;<DefaultButtonMui variant="contained" size="small" disabled={isErrorSelectedDates} onClick={onFilter}>{i18n.t('Show')}</DefaultButtonMui>
      &nbsp;<DefaultButtonMui variant="contained" size="small" onClick={onClickToday}>{i18n.t('today')}</DefaultButtonMui>
      &nbsp;<DefaultButtonMui variant="contained" size="small" onClick={onClickYesterday}>{i18n.t('yesterday')}</DefaultButtonMui>
    </label>
  )
});

const DataPicker: FC<{ item: ToolbarItemType, name: string }> = (props) => {
  const isMobile = useMediaDownMd();
  const { item, name } = props;
  return isMobile ? <Dialog iconButton={<EventIcon fontSize="inherit" />} item={item}><Component item={item} name={name} /></Dialog> : <Component item={item} name={name} />
};

export default DataPicker;
