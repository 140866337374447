import moment from 'moment';

export function formatDate(date: string | number | Date, formating = 'DD.MM.YYYY') {
  const value = moment(date);
  if (!value.isValid()) {
    return 'ERROR';
  }

  return value.utc().format(formating);
}
